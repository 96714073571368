const getPropertyStatus = (status, isRental, language) => {
    // console.log('* in getPropertyStatus - Property status =', status);
    // console.log('* in getPropertyStatus - Property isRental =', isRental);
    // console.log('* in getPropertyStatus - language =', language);

    if (status === 'VE') {
        if (isRental) {
          return (language === "en") ? "rented" : "loue";
        } else {
          return (language === "en") ? "sold" : "vendu";
        }
    }
    return "";
  }; 

  export default getPropertyStatus;