import React, { useState } from 'react';
import Broker from '../Broker/Broker';
import ImageGallery from 'react-image-gallery';
import { useTranslation, getI18n } from 'react-i18next';
import getPropertyStatus from '../../services/utils/getPropertyStatus';
import './PropertyDetails.scss';
import { CookieModal } from '../../components/CookieModal/CookieModal';

function PropertyDetails({property, brokers}) {
    const [showFeatures, setShowFeatures] = useState(true);
    const [showDescriptions, setshowDescriptions] = useState(false);
    const { t } = useTranslation();
    const language = getI18n().language;
    const address = property.inscription.ADRESSE + ', ' + property.inscription.municipality.DESCRIPTION + ', ' + property.inscription.CODE_POSTAL;
    const propertyType = language === 'en' ? property.inscription.genre.DESCRIPTION_ANGLAISE : property.inscription.genre.DESCRIPTION_FRANCAISE;
    const propertyDescription = language === 'en' ? property.descriptions.english : property.descriptions.french;

    const broker = brokers.find(broker => broker.code === property.inscription.COURTIER_INSCRIPTEUR_1);
    const isLOCATION = property.inscription.isLOCATION
    const price = isLOCATION ? property.inscription.PRIX_LOCATION_DEMANDE : property.inscription.PRIX_DEMANDE;
    const rent = t("property.rent");
    
    const formatedPrice = new Intl.NumberFormat('en-CA', {
      style: 'currency', 
      currency:'CAD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, }).format(price);
  
    const formatedPriceFR = new Intl.NumberFormat('fr-CA', {
      style: 'currency', 
      currency:'CAD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, }).format(price);
  
    const askingPrice = language === 'en' ? formatedPrice : formatedPriceFR;

    const toggleActiveTab = (e) => {
        e.preventDefault();
        if (!showFeatures && e.target.id === 'pills-features-tab') {
            setShowFeatures(!showFeatures);
            setshowDescriptions(!showDescriptions);
        } else if (!showDescriptions && e.target.id === 'pills-descriptions-tab') {
            setShowFeatures(!showFeatures);
            setshowDescriptions(!showDescriptions);
        }
    }

    const propertyStatus = getPropertyStatus(property.inscription.STATUT, isLOCATION, language);

    return (
        <>
        <div>
            <div className='container'>

         
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <div className='property-details'>
                        <ImageGallery 
                            items= {property.images} 
                            showBullets={false}
                        />   
                            <div className='text'>
                                <span className='subheading'>{language === 'en' ? property.inscription.quartier.DESCRIPTION_ANGLAISE : property.inscription.quartier.DESCRIPTION_FRANCAISE}</span>
                                <h2>{address}</h2>
                                <h2 className={`text-primary ${propertyStatus}`}>{isLOCATION ?  `${askingPrice} ${rent}`: `${askingPrice}`}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 pills'>
                        <div className='bd-example bd-example-tabs'>
                            <div className='d-flex'>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={showFeatures ? "nav-link show active" : "nav-link" } id="pills-features-tab" data-toggle="pill" href="#pills-feature" role="tab" aria-controls="pills-feature" aria-expanded="true" aria-selected={showFeatures ? "true" : "false"} onClick={toggleActiveTab}>{t("property.features")}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={showDescriptions ? "nav-link show active" : "nav-link" } id="pills-descriptions-tab" data-toggle="pill" href="#pills-description" role="tab" aria-controls="pills-description" aria-expanded="true" aria-selected={showDescriptions ? "true" : "false"} onClick={toggleActiveTab}>{t("property.description")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='tab-content' id='pills-tabContent'>
                                <div className={showFeatures ? "tab-pane fade show active" : "tab-pane fade" } id="pills-feature" role="tabpanel" aria-labelledby="pills-features-tab">
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <ul className="features">
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.livingArea")}: {property.inscription.SUPERFICIE_HABITABLE} {t("property.unitMeasure")}{ language === 'en' ? '' : <sup>2</sup>}</li>
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.bedrooms")}: {property.inscription.NB_CHAMBRES}</li>
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.bathrooms")}: {property.inscription.NB_SALLES_BAINS}</li>
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.rooms")}: {property.inscription.NB_PIECES}</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <ul className="features">
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.built")}: {property.inscription.ANNEE_CONTRUCTION} </li>
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.storeys")}: {property.inscription.NB_ETAGES}</li>
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.units")}: {property.inscription.NB_UNITES_TOTAL}</li>
                                                <li className="check"><span className="fa fa-check-circle"></span>{t("property.type")}: {propertyType}</li>
                                            </ul>
                                        </div>
                                        <p className='broker'></p>
                                        <Broker className='broker' broker={broker} isOne={brokers.length === 1}/>
                                    </div>
                                </div>
                                <div className={showDescriptions ? "tab-pane fade show active" : "tab-pane fade" } id="pills-description" role="tabpanel" aria-labelledby="pills-descriptions-tab">
                                    <div className='row'>
                                        <div className='col-md-7'>
                                        <p>{propertyDescription}</p>
                                        </div>
                                        <p className='broker'></p>
                                        <Broker broker={broker} isOne={brokers.length === 1}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>
        </>


    );

} 
export default PropertyDetails;