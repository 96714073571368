import React  from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import Homes from '../Homes/Homes';
import { useTranslation } from 'react-i18next';

function Properties({listings}) {
    const { t } = useTranslation();
    return (
        <section className='site-section bg-properties block-14' id='properties'>
        <div className='container'>
            <SectionHeader textColour={'white'} title={t("properties.latest")} description={t("properties.latestMessage")} />
            <Homes listings={listings} />
        </div>
        </section>
    );
} 
export default Properties;