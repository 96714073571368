import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './CookieModal.scss';


export const CookieModal = () => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const displayModal = () => {
        if (Cookies.get('cc_cookie_megii')) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    useEffect(() => {
        setTimeout(displayModal, 2000);

    }, [])

    const handleClose = () => {
        Cookies.remove('cc_cookie_megii');
        setShow(false);
    }
    
    const handleAccept = () => {
        const cookieValue = 'cc_cookie_megii.' + uuid();
        Cookies.set('cc_cookie_megii', cookieValue, {expires:90, secure:true});
        setShow(false);
    }

    return (
        <>
        <Modal 
            show={show} 
            onHide={handleClose}
            className='modal-dark-background'
            data-bs-theme="dark"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-white'>{t("cookies.modalTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {t("cookies.modalText")} &nbsp;
            <Link to="/cookie-policy">{t("cookies.cookiePolicyTitle")}</Link>.
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={handleClose}>
                    {t("cookies.modalButtonReject")}
                </Button>
                <Button variant="primary" onClick={handleAccept}>
                    {t("cookies.modalButtonAccept")}
                </Button>
            </Modal.Footer>
        </Modal>
        </>

    )
}