import React from 'react';
import Header from '../../layouts/Header/Header';
import HeroProperties from '../../layouts/HeroProperties/HeroProperties';
import AllHomes from '../../layouts/AllHomes/AllHomes';
import Footer from '../../layouts/Footer/Footer';
import { CookieModal } from '../../components/CookieModal/CookieModal';


function OurPropertiesPage({listings}) {

    return (
        <div>
            <Header/>
            <CookieModal/>
            <HeroProperties heroWrap={'hero-wrap-3'} heroTitle={"properties.title"} />
            <AllHomes listings={listings}/>
            <Footer/>
        </div>
    )
}

export default OurPropertiesPage;