import React from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import './HeroCookies.scss';

function HeroCookies({heroWrap, heroTitle}) {
  const { t } = useTranslation();
  const language = getI18n().language;

    return (

      <section className={`hero-wrap ${heroWrap} backgroundImg5`} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text align-items-center justify-content-center text-center">
          <div className="col-md-9 ftco-animate pb-0 text-center">
            <h1 className="mt-5 text-white">{heroTitle}</h1>
          </div>
        </div>
      </div>
    </section>

    );
}

export default HeroCookies;