import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, getI18n } from 'react-i18next';
import './listing.scss'
import getPropertyStatus from '../../services/utils/getPropertyStatus';

const Listing = (props) => {
  const property = props.property;
  const isLOCATION = property.inscription.isLOCATION
  const { t } = useTranslation();
  const language = getI18n().language;
  
  const price = isLOCATION ? property.inscription.PRIX_LOCATION_DEMANDE : property.inscription.PRIX_DEMANDE;
  const rent = t("property.rent");
  const formatedPrice = new Intl.NumberFormat('en-CA', {
    style: 'currency', 
    currency:'CAD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2, }).format(price);

  const formatedPriceFR = new Intl.NumberFormat('fr-CA', {
    style: 'currency', 
    currency:'CAD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2, }).format(price);

  const askingPrice = language === 'en' ? formatedPrice : formatedPriceFR;
  const region = language === 'en' ? property.inscription.region.DESCRIPTION_ANGLAISE : property.inscription.region.DESCRIPTION_FRANCAISE;
  const address1 = property.inscription.ADRESSE + ', ' + region + ', ';
  const address2 = property.inscription.CODE_POSTAL;
  
  const propertyStatus = getPropertyStatus(property.inscription.STATUT, isLOCATION, language);


  return (
            <div className={`item media-38289 ${propertyStatus}`}>
            {/* <div className="item media-38289 sold"> */}
              <Link to={`/properties/${property.inscription.NO_INSCRIPTION}`} className="d-block"><img src={property.photo_main} alt="Property" className="img-fluid"/></Link>
              <div className="text">
                <div className="d-flex justify-content-between mb-3">
                  <div className="sq d-flex align-items-center"><span className="wrap-icon icon-fullscreen"></span> <span>{property.inscription.SUPERFICIE_HABITABLE}</span></div>
                  <div className="bed d-flex align-items-center"><span className="wrap-icon icon-bed"></span> <span>{property.inscription.NB_CHAMBRES}</span></div>
                    <div className="bath d-flex align-items-center"><span className="wrap-icon icon-bath"></span> <span>{property.inscription.NB_SALLES_BAINS}</span></div>
                </div>
                <h3 className="mb-3"><Link to={`/properties/${property.inscription.NO_INSCRIPTION}`}>{isLOCATION ?  `${askingPrice}${rent}`: `${askingPrice}`}</Link></h3>
                <span className="d-block small address d-flex align-items-baseline"><span className="icon-room mr-2 text-primary"></span><span>{address1}<p>{address2}</p></span></span>
              </div>
            </div>
  
  );

}
export default Listing;