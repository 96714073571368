import axios from 'axios';
const baseUrl = 'https://megiiapi.megii.ca:8080/api/listings'; // For production
const baseUrlBrokers = 'https://megiiapi.megii.ca:8080/api/listings/brokers'; // For production

// const baseUrl = 'http://localhost:3001/api/listings'; // For development
// const baseUrlBrokers = 'http://localhost:3001/api/listings/brokers'; // For development

// console.log('process.env = ', process.env);
// console.log('window location = ', window.location.href);
const getAll = () => {
    const request =  axios.get(baseUrl);
    return request.then(response => response.data);
}

// const getAllBrokers = () => {
//     const request = axios.get(baseUrlBrokers);
//     console.log(request);
//     return request.then(response => response.data);
// }
function compareBrokers(broker1, broker2) {
    if (broker1.name === 'Eros Greatti') return -1;
    if (broker2.name === 'Eros Greatti') return 1;
    else {
        return broker1.code - broker2.code;
    }
}
async function getAllBrokers() {
    try {
        const response = await axios.get(baseUrlBrokers);
        const brokerList = response.data;
        return brokerList.sort(compareBrokers);
                
    } catch (error) {
        console.error('Error:',error);
    }
}

const listingInfo = {
    getAll, 
    getAllBrokers
}

export default listingInfo;