import React from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import getPropertyStatus from '../../services/utils/getPropertyStatus.js';
import './HeroProperties.scss';

function HeroProperties({heroWrap, heroTitle, mlsNum, propertyStatus}) {
  const { t } = useTranslation();
  const language = getI18n().language;

    return (

      <section className={`hero-wrap ${heroWrap} backgroundImg9`} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text align-items-center justify-content-center text-center">
          <div className="col-md-9 ftco-animate pb-0 text-center">
            {/* <h1 className={showAddress ? "icon-room mt-4 mr-3 text-primary" :"mt-5 text-white"}>{t(heroTitle)}</h1> */}
            <h1 className="mt-5 text-white">{t(heroTitle)}</h1>
            {
              mlsNum ? <h2 className={`text-primary mls ${propertyStatus}`}>Centris: # {mlsNum}</h2> : ""
            }
            
          </div>
        </div>
      </div>
    </section>

    );
}

export default HeroProperties;