import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/icomoon/style.css';
import $ from 'jquery';
import Popper from '@popperjs/core';

import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import listingInfo from './services/actions/listingsService.js';
import './i18n.js';
import 'animate.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

listingInfo
.getAll()
.then(listings => {
  listingInfo.getAllBrokers()
  .then(brokers => {
    root.render(
      <BrowserRouter>
        <App listings={listings} brokers={brokers} />
      </BrowserRouter>
    );
  }).catch(error => {
    console.error('GET broker failed. Error:', error);
  })
})
.catch(error => {
  console.error('GET failed. Error:', error)
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
