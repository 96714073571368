import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          navMenu: {
              properties: 'Properties',
              brokers: 'Brokers',
              testimonials: 'Testimonials',
              contact: 'Contact Us',
              lang: 'FR'
          },
          message: {
            button1: 'View Properties',
            button2: 'Coming soon!'
          },
          sectionTeam: {
            ourTeam: 'Our Brokers',
            ourTeamMsg: 'Our brokers are here to serve you ethically, with courtesy, professionalism and dignity. Call us today!'
          },
          brokerTitle: {
            erosGreatti: 'Certified Residential and Commercial Real Estate Broker AEO',
            marianaPortrait: 'Residential Real Estate Broker',
            stefanVarnev: 'Residential Real Estate Broker'
          },
          footer: {
            siteLinks: 'Site Links',
            socials: 'Socials',
            ourMissionTitle: 'Our Mission',
            ourMissionText: 'Promote the highest ethical and professional standards and collaboration among our members.  Ensure a smooth process and positive experience in the purchase or sale of your home and properties.',
            copyright: 'All rights reserved',
            address1:'274-729 Bourget Street',
            address2:'Montreal, Quebec H4C 2M6',
            addrTitle: 'Mailing address: '
          },
          property: {
            title: 'This property has mls number = ',
            link: 'Go to link: ',
            details: 'Property Details',
            rent: '/month',
            livingArea: 'Living area',
            unitMeasure: 'sqft',
            features: 'Features',
            description: 'Description',
            bedrooms: 'Bedrooms',
            bathrooms: 'Bathrooms',
            rooms: 'Number of rooms',
            type:'Property Type',
            storeys: 'Storeys',
            built: 'Built in',
            parking: 'Parking',
            units: 'Number of units'
          },
          properties: {
            title: 'Our Properties',
            latest:'Latest Properties',
            latestMessage: 'Check out our latest Real Estate listings.'
          },
          cookies: {
            modalTitle: 'This website uses cookies',
            modalText: 'We use cookies strickly to analyse website traffic and optimize your website experience. By accepting our use of cookies, your data will be aggregated with all other user data. Details about how we use cookies are set out in our ',
            cookiePolicyTitle: 'Cookie Policy',
            modalButtonAccept: 'Accept All',
            modalButtonReject: 'Close',
            whatAreCookiesTitle:'What are cookies?',
            whatAreCookiesText:'This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used, and how to manage the cookie settings. Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.',
            TypesCookies:'Types of Cookies we use',
            TypesCookiesNecessary:'Necessary',
            TypesCookiesNecessaryText:'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data. The listed cookies below are all classified as \"Necessary.\"',
            TypesCookiesAnalytics:'Analytics',
            TypesCookiesAnalyticsText:'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
            cookie:'Cookie',
            duration:'Duration',
            description:'Description',
            anonymousId:'This cookie is set by Centris, to count the number of people who visit a certain site by tracking if they have visited before.',	
            cookieMegii:'This cookie is set by megii, Used to remember that you have seen our cookies notification.',
            derivedEpik:'This cookie is set by Centris. Used by Pinterest. Placed when the Pinterest tag reports a match identified without using cookies, such as through enhanced match. The Pinterest tag caches information to make matching on future pages easier.',
            ga:'This cookie is set by Centris. Installed by Google Analytics, calculates visitor, session, campaign data, and also keeps track of site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.', 
            gclAu:'Provided by Google Tag Manager to experiment advertisement efficiency of websites using their services.',
            hjSessionUser:'This cookie is set by Centris, Used by Hotjar to store a unique user ID. used to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.',
            pinUnauth:'This cookie is set by Centris, Used by Pinterest. A first party cookie which groups actions for users who cannot be identified by Pinterest.',
            didomiToken:'This cookie is set by Centris. Used by Didomi. This cookie contains consent information for custom purposes and vendors, as well as Didomi-specific information (user ID, for instance).',
            euconsentV2:'This cookie is set by Centris. Used by Didomi. This cookie contains the IAB TCF consent string and consent information for all the standard IAB vendors and purposes.',
            never:'Never',
            days:'days',
            year:'year',
            month: 'month',
            months: 'months',
          }
        }
      },
      fr: {
        translation: {
          navMenu: {
              properties: 'Propriétés',
              brokers: 'Courtiers',
              testimonials: 'Témoignages',
              contact: 'Nous joindre',
              lang: 'EN'
          },
          message: {
            button1: 'Voir Propriétés',
            button2: 'À bientôt!'
          },
          sectionTeam: {
            ourTeam: 'Nos courtiers',
            ourTeamMsg: 'Nos courtiers vous servent de manière éthique, courtoise, professionnalle, et avec dignité. Appelez-nous dès aujourd\'hui!'
          },
          brokerTitle: {
            erosGreatti: 'Courtier immobilier résidentiel et commercial agréé DA',
            marianaPortrait: 'Courtier immobilier résidentiel',
            stefanVarnev: 'Courtier immobilier résidentiel'
          },
          footer: {
            siteLinks: 'Liens du site',
            socials: 'Sociaux',
            ourMissionTitle: 'Notre mission',
            ourMissionText: 'Promouvoir des normes élevées de manières éthiques et professionnelles et de collaboration entre nos membres. Assurer que le processus d\'achat ou de vente de votre maison et de vos propriétés se fasse de manière fluide et agréable.',
            copyright:'Tous droits réservés',
            address1:'274-729, rue Bourget',
            address2:'Montréal (Québec) H4C 2M6',
            addrTitle:'Adresse postale: '
          },
          property: {
            title: 'Cette propriété a le numéro mls = ',
            link: 'Suivez le lien: ',
            details: 'Détails propriétés',
            rent: '/mois',
            livingArea: 'Superficie habitable',
            unitMeasure: 'pi',
            features: 'Caractéristiques',
            description: 'Descriptif',
            bedrooms: 'Chambres à coucher',
            bathrooms: 'Salles de bain',
            rooms: 'Nombre de pièces',
            type:'Type de propriété',
            storeys: 'Étages',
            built: 'Date de construction',
            parking: 'Stationnement',
            units: 'Nombre d\'unités'
          },
          properties: {
            title: 'Nos Propriétés',
            latest: 'Propriétés récentes',
            latestMessage: 'Découvrez nos dernières inscriptions immobilières.'
          },
          cookies: {
            modalTitle: 'Ce site web utilise des fichiers témoins',
            modalText: 'Nous utilisons des témoins uniquement pour analyser le trafic du site Web et optimiser votre expérience sur le site Web. En acceptant notre utilisation des témoins, vos données seront agrégées avec toutes les autres données utilisateur. Les détails sur la façon dont nous utilisons les témoins sont présentés dans notre ',
            cookiePolicyTitle: 'Politique concernant les témoins',
            modalButtonAccept: 'Accepter tout',
            modalButtonReject: 'Fermer',
            whatAreCookiesTitle:'Qu\'est-ce que des fichiers témoins ?',
            whatAreCookiesText:'Cette politique relative aux fichiers témoins explique ce que sont les témoins et comment nous les utilisons. Elle détaille les types de témoins que nous employons, les informations que nous collectons à l\'aide des témoins et leur utilisation, ainsi que la gestion des paramètres des témoins. Les témoins sont de petits fichiers texte qui stockent de petites quantités d\'informations. Ils sont enregistrés sur votre appareil lorsque vous accédez au site Internet via votre navigateur. Ces témoins nous permettent d\'assurer le bon fonctionnement du site Web, de renforcer sa sécurité, d\'offrir une expérience utilisateur améliorée, d\'évaluer ses performances et d\'identifier les aspects à optimiser.',
            TypesCookies:'Types de témoins que nous utilisons',
            TypesCookiesNecessary:'Nécessaire',
            TypesCookiesNecessaryText:'Les cookies nécessaires sont requis pour activer les fonctionnalités de base de ce site, telles que la fourniture d\'une connexion sécurisée ou l\'ajustement de vos préférences de consentement. Ces cookies ne stockent aucune donnée personnelle identifiable. Les témoins répertoriés ci-dessous sont tous classés comme « nécessaires».',
            TypesCookiesAnalytics:'Analytique',
            TypesCookiesAnalyticsText:'Les cookies analytiques sont utilisés pour comprendre comment les visiteurs interagissent avec le site Web. Ces cookies aident à fournir des informations sur des mesures telles que le nombre de visiteurs, le taux de rebond, la source du trafic, etc.',
            cookie:'Témoin',
            duration:'Durée',
            description:'Description',
            anonymousId:'Ce témoin est défini par Centris pour compter le nombre de personnes qui visitent un certain site en suivant s\'ils l\'ont déjà visité.',	
            cookieMegii:'Ce témoin est défini par megii, utilisé pour se rappeler que vous avez lu notre notification concernant nos témoins.',
            derivedEpik:'Ce témoin est installé par Centris. Utilisé par Pinterest. Placé lorsque la balise Pinterest signale une correspondance identifiée sans utiliser de témoins, par exemple via une correspondance améliorée. La balise Pinterest met en cache les informations pour faciliter la correspondance sur les pages futures.',
            ga:'Ce témoin est installé par Centris. Installé par Google Analytics, calcule les données des visiteurs, des sessions et des campagnes et assure également le suivi de l\'utilisation du site pour le rapport d\'analyse du site. Le cookie stocke les informations de manière anonyme et attribue un numéro généré aléatoirement pour reconnaître les visiteurs uniques.', 
            gclAu:'Fourni par Google Tag Manager pour expérimenter l\'efficacité publicitaire des sites Web utilisant leurs services.',
            hjSessionUser:'Ce témoin est défini par Centris, utilisé par Hotjar pour stocker un identifiant utilisateur unique. utilisé pour conserver l\'ID utilisateur Hotjar, unique à ce site sur le navigateur. Cela garantit que le comportement lors des visites ultérieures sur le même site sera attribué au même identifiant utilisateur.',
            pinUnauth:'Ce témoin est déposé par Centris, utilisé par Pinterest. Un cookie interne qui regroupe les actions des utilisateurs qui ne peuvent pas être identifiés par Pinterest.',
            didomiToken:'Ce témoin est installé par Centris. Utilisé par Didomi. Ce témoin contient des informations de consentement à des fins personnalisées et des fournisseurs, ainsi que des informations spécifiques à Didomi (identifiant utilisateur, par exemple).',
            euconsentV2:'Ce témoin est installé par Centris. Utilisé par Didomi. Ce témoin contient la chaîne de consentement IAB TCF et les informations de consentement pour tous les fournisseurs et objectifs IAB standard.',
            never:'Jamais',
            days:'jours',
            year:'année',
            month: 'mois',
            months: 'mois',
          }
        }
      }
    }
  });

export default i18n;