import React from 'react';
import { useTranslation } from 'react-i18next';
import './CookiePolicyPage.scss';
import Header from '../../layouts/Header/Header';
import HeroCookies from '../../layouts/HeroCookies/HeroCookies';

function CookiePolicyPage() {
    const { t } = useTranslation();

    return (
        <>
        <Header/>
        <HeroCookies heroWrap={'hero-wrap-3'} heroTitle={t("cookies.cookiePolicyTitle")} />
        <div className="container justify-content-center">
            <h2 className='mt-3'>{t("cookies.whatAreCookiesTitle")}</h2>
            <p>
            {t("cookies.whatAreCookiesText")}
            </p>
            <h2>{t("cookies.TypesCookies")}</h2>
            <h4>{t("cookies.TypesCookiesNecessary")}</h4>
            <p>
            {t("cookies.TypesCookiesNecessaryText")}
            </p>
            <h4>{t("cookies.TypesCookiesAnalytics")}</h4>
            <p>
            {t("cookies.TypesCookiesAnalyticsText")}
            </p>
            <table className='table-striped mb-4'>
                <thead>
                    <th scope='col'>{t("cookies.cookie")}</th>
                    <th scope='col'>{t("cookies.duration")}</th>
                    <th scope='col'>{t("cookies.description")}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>AnonymousId</td>
                        <td>{t("cookies.never")}</td>
                        <td>
                        {t("cookies.anonymousId")}
                        </td>
                    </tr>
                    <tr>
                        <td>cc_cookie_megii</td>
                        <td>90 {t("cookies.days")}</td>
                        <td>
                        {t("cookies.cookieMegii")}
                        </td>
                    </tr>
                    <tr>
                        <td>_derived_epik</td>
                        <td>Session</td>
                        <td>
                        {t("cookies.derivedEpik")}
                        </td>
                    </tr>

                    <tr>
                        <td>_ga, _ga_*</td>
                        <td>1 {t("cookies.year")} 1 {t("cookies.month")} 4 {t("cookies.days")} 1 minute</td>
                        <td>
                        {t("cookies.ga")}
                        </td>
                    </tr>

                    <tr>
                        <td>_gcl_au</td>
                        <td>3 {t("cookies.months")}</td>
                        <td>
                        {t("cookies.gclAu")}
                        </td>
                    </tr>

                    <tr>
                        <td>_hjSessionUser_*</td>
                        <td>1 {t("cookies.year")}</td>
                        <td>
                        {t("cookies.hjSessionUser")}
                        </td>
                    </tr>

                    <tr>
                        <td>_pin_unauth</td>
                        <td>1 {t("cookies.year")}</td>
                        <td>
                        {t("cookies.pinUnauth")}
                        </td>
                    </tr>

                    <tr>
                        <td>didomi_token</td>
                        <td>1 {t("cookies.year")}</td>
                        <td>
                        {t("cookies.didomiToken")}
                        </td>
                    </tr>

                    <tr>
                        <td>euconsent-v2</td>
                        <td>1 {t("cookies.year")}</td>
                        <td>
                        {t("cookies.euconsentV2")}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}

export default CookiePolicyPage;
