import React from 'react';
import { Routes, Route } from 'react-router-dom'


import HomePage from './pages/HomePage/HomePage.jsx';
import OurPropertiesPage from './pages/OurPropertiesPage/OurPropertiesPage.jsx';
import PropertyPage from './pages/PropertyPage/PropertyPage.jsx';
import NotFound from './layouts/NotFound/NotFound.jsx';
import CookiePolicyPage from './pages/CookiePolicyPage/CookiePolicyPage.jsx';
import { CookieModal } from './components/CookieModal/CookieModal.jsx';



function App({listings, brokers}) {

  return (
        <Routes>
          <Route path="/">
              <Route index element={<HomePage listings={listings} brokers={brokers}/>}/>
          </Route>
          <Route path="properties">
            <Route index element={<OurPropertiesPage listings={listings} brokers={brokers} />}/>
            <Route path=":mls_no" element={<PropertyPage listings={listings} brokers={brokers}/>}/>
          </Route>
          <Route path="cookie-policy">
            <Route index element={<CookiePolicyPage/>}/>
          </Route>
          <Route path="*" element={<NotFound/>} />
        </Routes>
  );
}

export default App;
