import React from 'react';
import Header from '../../layouts/Header/Header';
import HeroProperties from '../../layouts/HeroProperties/HeroProperties';
import PropertyDetails from '../../layouts/PropertyDetails/PropertyDetails';
import Footer from '../../layouts/Footer/Footer';
import { useParams } from 'react-router-dom';
import getPropertyStatus from '../../services/utils/getPropertyStatus';
import {getI18n } from 'react-i18next';
import { CookieModal } from '../../components/CookieModal/CookieModal';

function PropertyPage({listings, brokers}) {
    const language = getI18n().language;
    const mls_no = useParams().mls_no;
    const propertyFound = listings.find(listing => listing.inscription.NO_INSCRIPTION === Number(mls_no));
    let propertyStatus = '';
    if (Object.keys(propertyFound).length > 0) {
        propertyStatus = getPropertyStatus(propertyFound.inscription.STATUT, propertyFound.inscription.isLOCATION, language);
    } else {
        console.log('NO property!')
    }

    return (
        <div>
        <Header />
        <CookieModal/>
        <HeroProperties heroWrap={'hero-wrap-3'} heroTitle={"property.details"} mlsNum={mls_no} propertyStatus={propertyStatus}/>
        <PropertyDetails property={propertyFound} brokers={brokers}/>
        <Footer/>
        </div>
    );
}
export default PropertyPage;